import React from 'react';
import './App.css';

function App() {
  return (
    <h1>Hello, World!</h1>
  );
}

export default App;
